import allGames from "./assets/allGames.svg?react";
import americanFootball from "./assets/americanFootball.svg?react";
import arrowLeft from "./assets/arrowLeft.svg?react";
import arrowRight from "./assets/arrowRight.svg?react";
import auth from "./assets/auth.svg?react";
import betHistory from "./assets/betHistory.svg?react";
import bonusGift from "./assets/bonusGift.svg?react";
import bonusStamp from "./assets/bonusStamp.svg?react";
import cardHearts from "./assets/cardHearts.svg?react";
import clock from "./assets/clock.svg?react";
import close from "./assets/close.svg?react";
import closeAlt from "./assets/closeAlt.svg?react";
import closedEye from "./assets/closedEye.svg?react";
import creditCard from "./assets/creditCard.svg?react";
import favourites from "./assets/favourites.svg?react";
import gamepad from "./assets/gamepad.svg?react";
import gift from "./assets/gift.svg?react";
import headset from "./assets/headset.svg?react";
import heart from "./assets/heart.svg?react";
import info from "./assets/info.svg?react";
import loading from "./assets/loading.svg?react";
import memberCard from "./assets/memberCard.svg?react";
import openedEye from "./assets/openedEye.svg?react";
import order from "./assets/order.svg?react";
import player from "./assets/player.svg?react";
import recommended from "./assets/recommended.svg?react";
import redCard from "./assets/redCard.svg?react";
import reload from "./assets/reload.svg?react";
import search from "./assets/search.svg?react";
import searchTicket from "./assets/searchTicket.svg?react";
import settings from "./assets/settings.svg?react";
import trophy from "./assets/trophy.svg?react";
import trophySolid from "./assets/trophySolid.svg?react";
import wallet from "./assets/wallet.svg?react";

export const Icons = {
  allGames,
  americanFootball,
  arrowLeft,
  arrowRight,
  auth,
  betHistory,
  bonusGift,
  bonusStamp,
  cardHearts,
  clock,
  close,
  closeAlt,
  closedEye,
  creditCard,
  favourites,
  gamepad,
  gift,
  headset,
  heart,
  info,
  loading,
  memberCard,
  openedEye,
  order,
  player,
  recommended,
  redCard,
  reload,
  search,
  searchTicket,
  settings,
  trophy,
  trophySolid,
  wallet,
};
